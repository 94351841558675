interface CatchErrorMessageType {
  message: undefined | string;
}

export const getCatchErrorMessage = <T>(error: T) => {
  let typeError: CatchErrorMessageType = { message: undefined };
  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    typeError = { ...typeError, message: error.message };
  }
  return typeError;
};
