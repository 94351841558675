import React, { lazy } from 'react';
import { ConfigProvider } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { defaultTheme } from '@turbocomplynpm/theme-provider';
import AuthorisedRoutes from 'login/authorised-routes';
import { Login } from 'login/login';
import SuspenseLoader from 'uikit/SuspenseLoader/suspense-loader';
import GoogleAuth from './containers/LoginPage/google-auth';

const Signup = lazy(() => import('containers/Signup/sign-up'));
const SuspenseLayout = lazy(() => import('containers/App/suspense-layout'));
const TermsAndPrivacy = lazy(() => import('containers/Terms&Privacy/term'));
const Policy = lazy(() => import('containers/Terms&Privacy/policy'));
const ForgotPassword = lazy(
  () => import('containers/ForgotPassword/forgot-password'),
);
const ResetPassword = lazy(
  () => import('containers/ResetPassword/ResetPassword'),
);

function LazyPolicy() {
  return (
    <SuspenseLoader>
      <Policy />
    </SuspenseLoader>
  );
}

function LazyForgotPassword() {
  return (
    <SuspenseLoader>
      <ForgotPassword />
    </SuspenseLoader>
  );
}

function LazyResetPassword() {
  return (
    <SuspenseLoader>
      <ResetPassword />
    </SuspenseLoader>
  );
}

function LazyTermsAndPrivacy() {
  return (
    <SuspenseLoader>
      <TermsAndPrivacy />
    </SuspenseLoader>
  );
}

function LazySignup() {
  return (
    <SuspenseLoader>
      <Signup />
    </SuspenseLoader>
  );
}

type MainRoutesProps = {
  handleLogout: () => void;
};

function MainRoutes({ handleLogout }: Readonly<MainRoutesProps>) {
  return (
    <ConfigProvider
      theme={{
        ...defaultTheme,
        token: {
          ...defaultTheme.token,
          fontSize: 14,
        },
      }}>
      <Switch>
        <Route exact path="/register" component={LazySignup} />
        <Route exact path="/login" component={Login} />
        <Route path="/auth/google/callback" component={GoogleAuth} />
        <Route exact path="/policy" component={LazyPolicy} />
        <Route exact path="/terms" component={LazyTermsAndPrivacy} />
        <Route exact path="/forgot-password" component={LazyForgotPassword} />
        <Route path="/update_password/" component={LazyResetPassword} />
        <AuthorisedRoutes path="/">
          <SuspenseLoader>
            <SuspenseLayout handleLogout={handleLogout} />
          </SuspenseLoader>
        </AuthorisedRoutes>
      </Switch>
    </ConfigProvider>
  );
}

export default MainRoutes;
