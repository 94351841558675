import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { AuthLoginAnimation } from '@turbocomplynpm/auth-login';
import Flex from 'uikit/Flex/Flex';
import Button from 'uikit/Button/Button';
import { Controller, useForm } from 'react-hook-form';
import InputText from 'uikit/InputText/InputText';
import Checkbox from 'uikit/Checkbox/checkbox';
import { EMAIL_RULES } from 'crm/clients/individuals/individual-rules';
import ErrorMessage from 'uikit/ErrorMessage/ErrorMessage';
import { useSaveAnimationHook } from 'uikit/save-animation/save-animation';
import { PASSWORD_RULES } from 'utils/validators';
import { useRecoilCallback } from 'recoil';
import { useHistory } from 'utils/router';
import { AnimationWrapper } from '@turbocomplynpm/animation-utils';
import { event } from '@turbocomplynpm/client-analytics';
import { loginCallback } from './atoms/login-atoms';
import { LoginFormTypes } from './atoms/login-types';
import { LoginLabel } from './login-label';
import * as styles from './login-form.module.css';

const cx = classNames.bind(styles);

export function LoginForm() {
  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
  } = useForm<LoginFormTypes>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      remember: false,
    },
  });
  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean | null>(
    null,
  );
  const { showAnimation, segments, onLoopComplete, setLoadingState } =
    useSaveAnimationHook({
      segmentOptions: {
        idle: [0, 0],
        pending: [0, 100],
        fulfilled: [100, 145],
        rejected: [100, 0],
      },
    });

  const history = useHistory();

  const loginCallbackHandler = useRecoilCallback(loginCallback);
  const emailValue = watch('email');
  useEffect(() => {
    if (typeof isRegisteredUser === 'boolean' && !showAnimation && emailValue) {
      if (isRegisteredUser) {
        history.replace('/company-list');
      } else {
        history.replace(`/register?step=company-detail&email=${emailValue}`);
      }
    }
  }, [isRegisteredUser, showAnimation, emailValue, history]);

  const onSubmit = (data: LoginFormTypes) => {
    setLoadingState('pending');
    loginCallbackHandler(data)
      .then((response) => {
        const { authenticated, status, isRegistered, tenant_id } = response;
        if (status === 'success' && authenticated) {
          setLoadingState('fulfilled');
          setIsRegisteredUser(isRegistered);
          event({
            category: 'user_login',
            action: 'create',
            label: tenant_id,
            value: 1,
          });
        } else {
          setLoadingState('rejected');
          event({
            category: 'user_login',
            action: 'create_failed',
            label: tenant_id,
          });
        }
      })
      .catch(() => {
        setLoadingState('rejected');
        event({
          category: 'user_login',
          action: 'create_failed',
          label: data.email,
        });
      });
  };

  if (showAnimation) {
    return (
      <Flex flex="1" className={cx('login-form')}>
        <AnimationWrapper className={cx('login-form__animation-wrapper')}>
          <AuthLoginAnimation
            segments={segments}
            onLoopComplete={onLoopComplete}
          />
        </AnimationWrapper>
      </Flex>
    );
  }

  return (
    <Flex flex="1" className={cx('login-form')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={EMAIL_RULES}
          render={({ field }) => (
            <InputText
              field={{ ...field }}
              className={cx('login-form__input')}
              placeholder="Enter email id"
              label="Email"
            />
          )}
        />
        <ErrorMessage name="email" errors={errors} touched={touchedFields} />
        <Controller
          name="password"
          control={control}
          rules={PASSWORD_RULES}
          render={({ field }) => (
            <>
              <LoginLabel>Password</LoginLabel>
              <InputText
                type="password"
                field={{ ...field }}
                className={cx('login-form__input')}
                placeholder="Enter password"
              />
            </>
          )}
        />
        <ErrorMessage name="password" errors={errors} touched={touchedFields} />
        <Flex row between>
          <Flex flex="1" center>
            <Controller
              name="remember"
              control={control}
              render={({ field }) => (
                <Checkbox
                  name="remember"
                  className={cx('login-form__checkbox')}
                  field={{ ...field }}>
                  Remember me
                </Checkbox>
              )}
            />
          </Flex>
          <Flex flex="1">
            <Button
              className={cx('login-form__button')}
              type="primary"
              htmlType="submit">
              Login
            </Button>
          </Flex>
        </Flex>
      </form>
    </Flex>
  );
}
