import React, { useEffect } from 'react';
import { useLogOutHook } from 'hooks/logoutHook';
import message from 'uikit/Message/Message';
import { removeUserSession } from 'utils/helper';
import { useHistory } from 'utils/router';
import { atom, useRecoilState } from 'recoil';
import MainRoutes from 'main-routes';
import { initialize } from '@turbocomplynpm/client-analytics';

const mainAtom = atom({
  key: 'mainAtom',
  default: '',
});

function MainApp() {
  const [mainAtomValue, setMainAtom] = useRecoilState(mainAtom);

  useEffect(() => {
    setMainAtom('initApp');
    initialize({
      options: [
        {
          trackingId: 'G-0LYL85NL7L',
        },
      ],
    });
  }, [setMainAtom]);

  const { clearAllAtom } = useLogOutHook();
  const history = useHistory();
  const handleLogout = () => {
    removeUserSession();
    clearAllAtom();
    message.success('Logout Successfull');
    history.push('/login');
    setMainAtom('initApp');
  };
  return (
    <>
      {mainAtomValue === 'initApp' && (
        <MainRoutes handleLogout={handleLogout} />
      )}
    </>
  );
}

export default MainApp;
